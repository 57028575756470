import { Box, Tab, Tabs, Typography } from "@mui/material";
import { featureType } from "../../utils/types";
import "./FeaturedSections.scss";
import { useState } from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx: any;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, sx, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={sx}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const FeaturedSections = ({features}: {features: featureType[]}) => {
    const [tab, setTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    return (
        <Box>
            <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{color: 'white', borderRight: 1, borderColor: 'divider' }}
            >
                {
                    features.map((feature)=>Object.keys(feature).map((featureKey:string)=>
                        <Tab key={featureKey} label={featureKey}/>
                    ))
                }
            </Tabs>
            {
                features.map((feature)=>(<>
                    {
                        Object.keys(feature).map((featureKey:string,index:number)=>
                            <TabPanel key={featureKey} value={tab} index={index} sx={{maxHeight: 'calc(min(400px, 80vw) - 120px)', overflow: 'scroll'}}>
                                {feature[featureKey].map((text:string,idx:number)=><Typography key={idx}>{text}</Typography>)}
                            </TabPanel>
                        )
                    }
                </>))
            }
        </Box>
    )
};

export default FeaturedSections;