import React, {useRef, useState} from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { HandleNavigationContext } from './utils/context';
import MainContentPage from './components/MainContentPage/MainContentPage';
import { SnackbarProvider } from 'notistack';

function App() {
  const IntroductionRef = useRef<HTMLHeadingElement>(null);
  const ExperienceRef = useRef<HTMLHeadingElement>(null);
  const ProjectsRef = useRef<HTMLHeadingElement>(null);
  const ContactRef = useRef<HTMLHeadingElement>(null);
  const handleNavigate = (toRef=IntroductionRef as any) => {
    toRef.current?.scrollIntoView({ behavior: 'smooth'});
  }
  return (
    <HandleNavigationContext.Provider value={{handleNavigate}}>
      <Header refs={{IntroductionRef, ExperienceRef, ProjectsRef, ContactRef}}/>
        <SnackbarProvider><MainContentPage refs={{IntroductionRef, ExperienceRef, ProjectsRef, ContactRef}}/></SnackbarProvider>
      <Footer refs={{IntroductionRef, ExperienceRef, ProjectsRef, ContactRef}}/>
    </HandleNavigationContext.Provider>
  );
}

export default App;
