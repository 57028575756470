import axios from 'axios';

export const postAuthApi = async (route: string, postData:any, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, navigate=(key:string) =>{console.log('a')}, config={withCredentials: true, credentials: 'include', headers: { "Authorization": `${JSON.parse(localStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${localStorage.getItem('accessToken')}` }} as any) => {
    try {
        const { data } = await axios.post(route, postData, config);
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
        if(message.type==='SIGNEDOUT'){
            localStorage.clear()
            failureCallback({message: message});
            navigate('/login');
        } else if(message.type==='SYNCERR'){
            try{
                const res = await axios.get('/api/refreshToken', config);
                localStorage.setItem('accessToken', res.data.accessToken);
                const { data } = await axios.post(route, postData, {...config, headers: {...config.headers, "Authorization": `${JSON.parse(localStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${res.data.accessToken}`}});
                successCallback(data);
            } catch (err:any){
                failureCallback({message: message});
            }
        } else {
            
            failureCallback({message: message});
        }
    }
};
export const getAuthApi = async (route: string, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, navigate=(key:string)=>{console.log('a');}, config={withCredentials: true, credentials: 'include', headers: { "Authorization": `${JSON.parse(localStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${localStorage.getItem('accessToken')}` }} as any) => {
    try {
        const { data } = await axios.get(route, config);
        successCallback(data);
    } catch (err:any) {
        try{
            let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
            if(message.type==='SIGNEDOUT'){
                localStorage.clear()
                failureCallback({message: message});
                navigate('/login');
            } else if(message.type==='SYNCERR'){
            try{
                const res = await axios.get('/api/refreshToken', config);
                localStorage.setItem('accessToken', res.data.accessToken);
                const { data } = await axios.get(route, {...config, headers: {...config.headers, "Authorization": `${JSON.parse(localStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${res.data.accessToken}`}});
                successCallback(data);
            } catch (err:any){
                failureCallback({message: message});
            }
        } else {
            failureCallback({message: message});
        }
    } catch(err:any){
        failureCallback({message: {type: "ERROR", status: 500, msg: "An unknown error has occurred."}});
    }
    }
}
export const postApi = async (route: string, postData:any, successCallback: (data:any) => void, failureCallback: (data:any) => void) => {
    try {
        const { data} = await axios.post(route, postData, {withCredentials: true, headers: {'Content-Type': 'application/json'}});
        successCallback(data);
    } catch (err:any) {
        let message = err;
        failureCallback(message);
    }
}
export const getApi = async (route: string, successCallback: (data:any) => void, failureCallback: (data:any) => void) => {
    try {
        const { data} = await axios.get(route, {withCredentials: true});
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
        failureCallback(message);
    }
}