import { forwardRef, useContext } from 'react';
import "./Contact.scss";
import { FadeContext } from '../../utils/context';
import ContactForm from '../ContactForm/ContactForm';
const Contact = (props:any, ref: any) => {
    const fades = useContext(FadeContext);
    return <div className={"content-section" + (fades.contact?" revealed":"")}>
        <div className={"contact-container"}>
            <ContactForm ref={ref}/>
        </div>
    </div>
};
export default forwardRef(Contact);