import { forwardRef, useContext, useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ArticleIcon from '@mui/icons-material/Article';
import "./Projects.scss";
import { FadeContext } from '../../utils/context';
import { Button, Card, Typography } from '@mui/material';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
import ProjectItem from '../ProjectItem/ProjectItem';
import { projects } from '../../utils/data';
const Projects = (props:any, ref: any) => {
    const fades = useContext(FadeContext);
    return <div className={"content-section" + (fades.projects?" revealed":"")}>
        <h2 ref={ref}>Projects</h2>
        <div className={"projects-container"}>
            {
                projects.map((project)=>(
                    <ProjectItem title={project.title} videoLink={project.videoLink} slides={project.slides} features={project.features}/>
                ))
            }
        </div>
        <div className="project-footer">
            <a href="https://calendly.com/sharpdevtrev/" target="_blank">
                <p>Want to see more projects?</p> <Button sx={{background: "#ff6600", '&:hover': {background: "#800000"}}} variant="contained">Schedule A Meeting</Button>
            </a>
        </div>
    </div>
};
export default forwardRef(Projects);