import Vip0 from '../assets/vip-0.png';
import Phoenix0 from '../assets/ph-0.png';
import Phoenix2 from '../assets/ph-2.webp';
import Tdesign0 from '../assets/tdesign-0.png';
import Vip1 from '../assets/vip-1.png';
import Vip2 from '../assets/vip-2.png';
import Vip3 from '../assets/vip-3.png';
import Vip4 from '../assets/vip-4.png';
import Vip5 from '../assets/vip-5.png';
export const projects = [
    {
        title: "Phoenix Lounge",
        videoLink: "",
        slides: [
            {'title': 'First', 'url': Phoenix0},
            {'title': 'Second', 'url': Phoenix2},
        ],
        features: [
            {
                'About': [],
                'Role': ["As the Full Stack Engineer, I was required to implement features and functionality.", "I developed the product from the ground up, and deployed it across the AWS cloud infrastructure.", "I ensured end-to-end encryption with with an added application layer encription ensuring encryption at rest."],
                'Features': ["Ecommerce", "Encrypted Messaging", "Encrypted file sharing", "charts", "Assessment reporting"],
                'Technologies': ["React", "Sass/SCSS", "Express", "MySQL", "Redis", "AWS"],
            }
        ]
    },{
        title: "Visualize IP",
        videoLink: "",
        slides: [
            {'title': 'First', 'url': Vip0},
            {'title': 'Second', 'url': Vip1},
            {'title': 'Third', 'url': Vip2},
            {'title': 'Fourth', 'url': Vip3},
            {'title': 'Fifth', 'url': Vip4},
        ],
        features: [
            {
                'About': ["As the Senior full stack engineer, I was responsible for bringing key features and services to the platform", "I created a messaging application so that users could quickly create requests directly to our admin", "As well I created a fully fledged adnimistration dashboard for site and user management."],
                'Role': ["Senior Full Stack Engineer", "Analytics and Data engineer, for automation and data collection", "DevOps, continuously developing and integrating into our application workflow."],
                'Features': ["B2B Software as a Service", "Computer Vision intelectual property searches on patents", "Ecommerce"],
                'Technologies': ["React", "Sass/SCSS", "Python (Django)", "Node.js", "AWS", "Redis", "PostgreSQL"],
            }
        ]
    },
    {
        title: "Custom T-Shirt Designer",
        videoLink: "",
        slides: [
            {'title': 'First', 'url': Tdesign0}
        ],
        features: [
            {
                'About': ["This is a customizable T-Shirt designer that allows customers to fully customize their T-shirt by allowing them to dynamically select any RGB T-shirt color they like (or a select among depending on site settings", "T-shirt can then be customized by adding assets to get the perfect design for your business."],
                'Role': ["Sole developer and designer"],
                'Features': ["Ecommerce ready", "Custom Web UI Components"],
                'Technologies': ["React", "Sass/SCSS"],
            }
        ]
    }
];