import React from 'react';

import "./Logo.scss";
const Logo = () => {
    return (
        <svg
            viewBox="0 0 62 62"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g
                transform="matrix(0.9999931,0,0,1,0.2,12.090261)">
                <path
                    d="m 27.151832,7.14375 q -5.720581,0 -5.720581,21.79402 v 9.15293 h -9.515699 v -7.701856 q 0,-23.245094 -5.720581,-23.245094 H 0 V 0 h 33.346803 v 7.14375 z"
                    style={{fill:"#800000", strokeWidth: 0.264583}}
                />
                <path
                    d="m 32.995425,38.0907 v -7.143751 h 18.947681 q 0,-2.651001 -1.00459,-4.632275 -1.227832,-2.511475 -3.655591,-2.511475 -14.2875,0 -14.2875,-23.44043 V 0 h 28.575 V 7.14375 H 42.511123 q 0,3.348633 0.865064,5.860108 1.227832,3.655591 3.906738,3.655591 8.064624,0 11.608594,7.14375 2.678906,5.329908 2.678906,14.287501 z"
                    style={{fill:"#ff6600", strokeWidth:0.264583}}
                />
                <path
                    d="m 33.161359,30.954212 h -9.897076 l 4.948538,-9.897076 -4.948538,-9.868137 h 9.897076 l 4.9196,9.868137 z"
                    style={{fill:"#ff0000", strokeWidth:0.264583}}
                />
            </g>
        </svg>
    )
}

export default Logo;