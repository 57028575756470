import { forwardRef, useContext } from 'react';
import "./Experience.scss";
import { FadeContext } from '../../utils/context';
const Experience = (props:any, ref: any) => {
    const fades = useContext(FadeContext);
    return <div className={"content-section" + (fades.experience?" revealed":"")}>
        <h2 ref={ref}>Experience</h2>
        <div className={"experience-container"}>
            <div className={"experience-item"}>
                <div className={"experience-header"}>
                    <h3 className={"experience-company"}>Visualize IP</h3>
                    <p className={"experience-divider"}> - </p>
                    <p className={"experience-title"}>Senior Full Stack Engineer</p>
                </div>
                <div className={"experience-content"}>
                    <p>Visualize IP is a Pioneer in Computer Vision Patent Search Technology</p>
                    <p>Visualize IP utilizes A.I. to search for Design FTO, Novelty and Landscape and well as Invalidity and Post-grant Proceeding.</p>
                    <p>At Visualize IP I was brought in to fix errors in product delivery and add the handling of payments (with Stripe).</p>
                    <p>After fullfilling those initial error fixes and payment integration I was asked to stay as a full stack dev.</p>
                    <p>Since then I've played a key role not only in their entire stack and delivery to their customers, but also administer architectual changes.</p>
                    <p>I regularly worked with feature requests of Visualize IP's clients and integrated those smoothly into our work flow.</p>
                    <p>Utlizing my skills I've brought key resources and features requested by clients and have optimized the SaaS platform on all levels.</p>
                    <p>You can check out Visualize IP's goals and mission <a href="https://www.visualizeip.com" target="_blank">Here</a></p>
                </div>
                
            </div>
            <div className={"experience-item"}>
                <div className={"experience-header"}>
                    <h3 className={"experience-company"}>Freelancer</h3>
                    <p className={"experience-divider"}> - </p>
                    <p className={"experience-title"}>Full Stack Engineer</p>
                </div>
                <div className={"experience-content"}>
                    <p>Developed and deployed <a href="https://www.phoenixlounge.org" target="_blank">Phoenix Lounge</a>, a Dietatic practice connecting patience to their Rd.</p>
                    <p><a href="https://www.phoenixlounge.org" target="_blank">Phoenix Lounge</a> is a mobile wellness center. See the Projects section for more details.</p>
                    <p><a href="https://www.phoenixlounge.org" target="_blank">Phoenix Lounge</a> Software is HIPPA compliant and meets or exceeds standard practices.</p>
                    <p>Other tools and software I've contributed include but are not limited to -&gt;</p>
                    <p>Developed a custom T-Shirt designer tool (client site pending)... See Projects for demo.</p>
                    <p>I offered my professional expertise to clients and maintained a <strong>100% Job success rate</strong> on <a href="https://www.upwork.com/freelancers/sharpdev" target="_blank">Upwork</a>. </p>
                    <p>While working on the platform I received a JavaScript(backend) certification.</p>
                    <p>Other projects are under NDA, please ask about the technologies I used!</p>
                    <p>I worked with my different products and services as well as <strong>3rd Party API's</strong>.</p>
                </div>
            </div>
        </div>
    </div>
};
export default forwardRef(Experience);