import { Button, Card } from "@mui/material"
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ArticleIcon from '@mui/icons-material/Article';
import { useState } from "react";
import "./ProjectItem.scss";
import ImageSlider from "../ImageSlider/ImageSlider";
import FeaturedSections from "../FeaturedSections/FeaturedSections";
import { projectType } from "../../utils/types";

const ProjectItem = ({title, slides, videoLink, features}: projectType) => {
    const [view, setView] = useState(0);
    return (
        <Card className="project-item">
            <div className="project-header">
                <h3>{title}</h3>
                <div className="project-tray">
                    <Button variant="contained" sx={view===0?{}:{backgroundColor: "#000000"}} onClick={()=>{setView(0)}}>
                        <ViewCarouselIcon />
                    </Button>
                    {videoLink!=='' && (<Button variant="contained" sx={view===1?{}:{backgroundColor: "#000000"}} onClick={()=>{setView(1)}}>
                        <OndemandVideoIcon />
                    </Button>)
                    }
                    <Button variant="contained" sx={view===2?{}:{backgroundColor: "#000000"}} onClick={()=>{setView(2)}}>
                        <ArticleIcon />
                    </Button>
                </div>
            </div>
            {view===0?(
                <ImageSlider slides={slides} />
            ):(view===1?(
                <YoutubeEmbed embedId={videoLink} />
            ):(
                <FeaturedSections features={features}/>
            ))
            }
        </Card>
    )
}

export default ProjectItem;