
import "./Footer.scss";
const Footer = (props: any) => {
    return (
        <div className="footer">
            <div className="copyright-notice">
                <p>&copy; 2024 Trevor Sharp</p>
            </div>
            <div className="responsive-space"></div>
        </div>
    )
};

export default Footer;