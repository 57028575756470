import { forwardRef } from 'react';
import PfpJpg from '../../assets/pfp.jpg';
import PfpWebp from '../../assets/pfp.webp';
import "./Introduction.scss";
import LinkedInIcon from '../Icons/LinkedIn';
import GithubIcon from '../Icons/Github';
import StackOverflowIcon from '../Icons/StackOverflow';
import GTwoIIcon from '../Icons/GTwoI';
import HiredIcon from '../Icons/Hired';
import TyperacerIcon from '../Icons/Typeracer';
import WellfoundIcon from '../Icons/Wellfound';
const Introduction = (props:any, ref: any) => {
    return <div className="introduction-section content-section revealed">
        <h1 ref={ref}><span>Results Driven </span><span className="highlight">Engineering</span></h1>
        <div className="slide-in-from-right slow">
            <div className="summary-card-wrapper">
                <div className="summary-card">
                    <div className="content-information">
                        <div>
                            <p>Hello, I'm <strong>Trevor Sharp</strong>, a Full Stack Engineer.</p>
                            <p>I have a diverse set of skills and experiences.</p>
                            <p>Worked for a <strong>B2B SaaS</strong> startup company.</p>
                            <p>Developed and deployed <strong>HIPPA compliant</strong> software.</p>
                            <p>I <strong>Love</strong> (and am good at) learning new things.</p>
                            <p>Worked with <strong>3rd party API's</strong></p>
                            <p>Payment integrations with <strong>Stripe</strong> and <strong>PayPal</strong></p>
                            <p>Strong working experience with <strong>AWS</strong>.</p>
                            <p>Always looking to benefit from the <strong>wisdom of others</strong>.</p>
                        </div>
                    </div>
                    <div className="profile-links">
                        <div className="pfp-content">
                            <picture>
                                <source srcSet={PfpWebp} type="image/webp" />
                                <source srcSet={PfpJpg} type="image/jpeg" />
                                <img src={PfpJpg} alt="Profile Icon" />
                            </picture>
                            <p>Trevor Sharp</p>
                        </div>
                        <div className="pfp-accounts">
                            <div className="row-accounts">
                            <a href="https://stackoverflow.com/users/10108332/sharp-dev" target="_blank" rel="noreferrer" className="pfp-icon"><StackOverflowIcon /></a>
                                <a href="https://www.linkedin.com/in/trevor-sharp-dev/" target="_blank" rel="noreferrer" className="pfp-icon"><LinkedInIcon /></a>
                            </div>
                            <div className="row-accounts">
                                <a href="https://wellfound.com/u/trevor-sharp-2" target="_blank" rel="noreferrer" className="pfp-icon"><WellfoundIcon /></a>
                                <a href="https://github.com/TISHARP" target="_blank" rel="noreferrer" className="pfp-icon"><GithubIcon /></a>
                            </div>
                            {/*<div className="row-accounts" style={{height: '60px'}}></div>*/}
                            <div className="row-accounts">
                                <a href="https://data.typeracer.com/pit/profile?user=bevigilantheiscomingback" target="_blank" rel="noreferrer" className="pfp-icon"><TyperacerIcon /></a>
                                <a href="https://www.hackerrank.com/profile/sharpdevtrev" target="_blank" rel="noreferrer" className="pfp-icon"><img style={{width: '50px'}} src="https://hrcdn.net/fcore/assets/work/header/hackerrank_logo-21e2867566.svg" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default forwardRef(Introduction);