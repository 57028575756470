import Logo from '../Logo/Logo';
import "./HeaderB.scss";
import { Button } from '@mui/material';
import { useContext } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { HandleNavigationContext } from '../../utils/context';
const Header = (props: any) => {
    const links = [
        {to: props.refs.IntroductionRef, title: 'Home', icon: <HomeIcon />},
        {to: props.refs.ExperienceRef, title: 'Experience', icon: <AssignmentIndIcon />},
        {to: props.refs.ProjectsRef, title: 'Projects', icon: <ArchitectureIcon />},
        {to: props.refs.ContactRef, title: 'Contact', icon: <ContactMailIcon />},
    ];
    const {handleNavigate} = useContext(HandleNavigationContext);
    return (
        <div className="header">
            <nav>
                <span className="logo-link" onClick={()=>{handleNavigate(links[0].to);}}><Logo /></span>
                <div className={"main-links"}>
                    <ul className={"desktop-page-links"}>
                        {
                            links.map((link)=>(
                                <li key={link.title}>
                                    <Button onClick={()=>{handleNavigate(link.to); }}><span className={"link-text"}>{link.title}</span></Button>
                                </li>
                            ))
                        }
                    </ul>
                    <ul className={"mobile-page-links"}>
                        {
                            links.map((link)=>(
                                <li key={link.title} aria-label={"Link-To-"+link.title}>
                                    <Button onClick={()=>{handleNavigate(link.to); }}><span className={"link-text"}>{link.icon}<span className="label-icon">{link.title}</span></span></Button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </nav>
        </div>
    )
};

export default Header;