import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import "./ContactForm.scss";
import { useRef, useState, forwardRef } from 'react';
import { postApi } from '../../apis/helpers';
import { useSnackbar } from 'notistack';

const isValid = (v: string, str:string) => {
    if(v==="name"){
        return str.length>0;
    } else if(v==="email"){
        return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str)
    } else if(v==="msg"){
        return str.length > 14;
    }
    return false;
}

const ContactForm = (props: any, ref: any) => {
    const nameInput = useRef<HTMLInputElement>(null);
    const emailInput = useRef<HTMLInputElement>(null);
    const messageInput = useRef<HTMLTextAreaElement>(null);
    const [state, setState] = useState({
        name: {val: '', isError: true},
        email: {val: '', isError: true},
        msg: {val: '', isError: true},
    } as any);
    const [isPosting, setIsPosting] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleChange = (v:string) => (e: any) => {
        setState({...state, [v]: {...state[v], val: e.target.value, isError: !isValid(v, e.target.value)}});
    }
    const onSuccess = (data:any) => {
        setState({
            name: {val: '', isError: true},
            email: {val: '', isError: true},
            msg: {val: '', isError: true},
        });
        setIsPosting(false);
        closeSnackbar();
        enqueueSnackbar("Thank you for reaching out, your message has been sent.", {variant: 'success'});
    }
    const onFailure = (data:any) => {
        setIsPosting(false);
        closeSnackbar();
        enqueueSnackbar("Your message could not be sent, try again or try another method.", {variant: 'error'});
    }
    const handleSubmit = (e:any) => {
        e.preventDefault();
        if(state.name.isError||state.email.isError||state.msg.isError||isPosting) return;
        setIsPosting(true);
        postApi("https://demo.sharptrev.dev/api/contact-message", {name: state.name.val, email: state.email.val, msg: state.msg.val}, onSuccess, onFailure);
    }
    return (
        <section className="contact-us-area">
            <div className="contact-content">
                <h2 ref={ref}>Contact Me</h2>
                <p>Want to see where I can bring value? Please reach out.</p>
            </div>
            <div className="container">
                <div className="contactInfo">
                    <div className="box">
                        <div className="icon"><PhoneIcon /></div>
                        <div className="text">
                            <h3>Phone</h3>
                            <p>REDACTED</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><MailOutlineIcon /></div>
                        <div className="text">
                            <h3>Email</h3>
                            <p>sharpdevtrev@gmail.com</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><CalendarMonthIcon /></div>
                        <div className="text schedule-meeting">
                            <h3>Schedule Meeting</h3>
                            <a href="https://calendly.com/sharpdevtrev/" target="_blank">Schedule Here</a>
                        </div>
                    </div>
                    <div className="box">
                        <div className="facebook-icon"></div>
                        <div className="instagram-icon"></div>
                        <div className="linkedin-icon"></div>
                    </div>
                </div>
                <div className="contactForm">
                    <form>
                        <h2>Send Message</h2>
                        <div className="inputBox">
                            <input id="firstname-input" type="text" name="name" required ref={nameInput} value={state.name.val} onChange={handleChange("name")} className={((state.name.isError||state.email.isError||state.msg.isError||isPosting)?"disabled":"")+(state.name.isError?" error":"")}/>
                            <span onClick={()=>{nameInput.current?.focus()}}>Full Name</span>
                        </div>
                        <div className="inputBox">
                            <input type="text" name="email" required ref={emailInput} value={state.email.val} onChange={handleChange("email")} className={state.email.isError?"error":""}/>
                            <span onClick={()=>{emailInput.current?.focus()}}>Email</span>
                        </div>
                        <div className="inputBox">
                            <textarea rows={5} required ref={messageInput} value={state.msg.val} onChange={handleChange("msg")} className={state.msg.isError?"error":""}></textarea>
                            <span onClick={()=>{messageInput.current?.focus()}}>Please detail your request...</span>
                        </div>
                        <div className="inputBox submit-btn">
                            <input type="submit" name="submit-btn" value="Send" onClick={handleSubmit} className={(state.name.isError||state.email.isError||state.msg.isError||isPosting)?"disabled":""}/>
                        </div>
                        <div className="secure-msg">
                            <LockTwoToneIcon />
                            <span className="info-msg">Messages are encrypted and kept confidential.</span>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default forwardRef(ContactForm);