import { useContext, useEffect, useState } from 'react';
import "./MainContentPage.scss";
import Introduction from '../Introduction/Introduction';
import Experience from '../Experience/Experience';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import { FadeContext } from '../../utils/context';
import { HandleNavigationContext } from '../../utils/context';
const MainContentPage = (props:any) => {
  const [fades, setFades] = useState({experience: false, projects: false, contact: false});
  const {handleNavigate} = useContext(HandleNavigationContext);
  useEffect(() => {
    const windowHeight = window.innerHeight;
    const revealPoint = 100;
    const IntroPoint = props.refs.IntroductionRef.current.getBoundingClientRect().top;
    const BodyPoint = document.body.getBoundingClientRect().top;
    const temp = {...fades};
    if(IntroPoint < -10 && IntroPoint > -45 && BodyPoint <= 0 && BodyPoint >=-30){
      handleNavigate(props.refs.IntroductionRef)
    }
    if(props.refs.ExperienceRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.experience = true;
    else temp.experience = false;
    if(props.refs.ProjectsRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.projects = true;
    else temp.projects = false;
    if(props.refs.ContactRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.contact = true;
    else temp.contact = false;
    setFades(temp);
    const onScroll = (e:any) => {
      const windowHeight = window.innerHeight;
      const revealPoint = 100;
      const temp = {...fades};
      const IntroPoint = props.refs.IntroductionRef.current.getBoundingClientRect().top;
      const BodyPoint = document.body.getBoundingClientRect().top;
      if(IntroPoint < -10 && IntroPoint > -45 && BodyPoint <= 0 && BodyPoint >=-30){
        handleNavigate(props.refs.IntroductionRef)
      }
      if(props.refs.ExperienceRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.experience = true;
      else temp.experience = false;
      if(props.refs.ProjectsRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.projects = true;
      else temp.projects = false;
      if(props.refs.ContactRef.current.getBoundingClientRect().top < windowHeight - revealPoint) temp.contact = true;
      else temp.contact = false;
      setFades(temp);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  },[]);
  return (
    <FadeContext.Provider value={fades}>
      <Introduction ref={props.refs.IntroductionRef} />
      <Experience ref={props.refs.ExperienceRef} />
      <Projects ref={props.refs.ProjectsRef} />
      <Contact ref={props.refs.ContactRef} />
    </FadeContext.Provider>
  );
};

export default MainContentPage;