import { useState } from "react";
import { slideType } from "../../utils/types";
import "./ImageSlider.scss";

const ImageSlider = ({slides}: {slides: slideType[]}) => {
    const [slide, setSlide] = useState(0);
    const navigateToSlide = (newSlide:number) => () => {
        console.log(newSlide);
        setSlide(newSlide);
    }
    return (
        <div className="image-slider">
            <div className="l-r-image-navigation">
                <div className="navigate-container" onClick={navigateToSlide((slide+slides.length-1)%slides.length)}>
                    <div className="navigate-previous">
                        ❰
                    </div>
                </div>
                <div className="navigate-container" onClick={navigateToSlide((slide+1)%slides.length)}>
                    <div className="navigate-next">
                        ❱
                    </div>
                </div>
                
            </div>
            <div className="slides" style={{width: (slides.length*100)+"%", transform: 'translateX(-'+((slide/slides.length)*100)+'%)'}}>
                {
                    slides.map((slide)=>(
                        <div className="slide" key={slide.title} style={{width: (100/slides.length)+"%"}}>
                            <img src={slide.url} alt={slide.title}/>
                        </div>
                    ))
                }
            </div>
            <div className="dot-image-navigation">
                {slides.map((slide, sidx) => (
                    <div onClick={navigateToSlide(sidx)}>
                        ●
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ImageSlider;