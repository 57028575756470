

const WellfoundIcon = () => {
    return (
        <svg fill="currentColor" viewBox="0 0 554.89997 266.70002" width="50">
            <path d="M 80.9,263.59999 0,2.9999988 H 75.3 L 123,190.39999 174.9,2.9999988 h 75.5 L 302.3,190.39999 350,2.9999988 h 75.3 L 343.5,263.59999 H 263.3 L 212.7,75.399999 161.1,263.59999 H 80.9 Z"></path>
            <circle cx="511.09995" cy="222.89999" fill="#EC2E3A" r="43.799999"></circle>
            <circle cx="511.09995" cy="43.799999" fill="#EC2E3A" r="43.799999"></circle>
        </svg>
    )
}

export default WellfoundIcon;